<template>
  <div>
    <TagbyFilterV2
      :initialParams="filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    >
      <template #buttons>
        <div
          v-if="!hasRequestTime"
          class="text-danger"
        >
          하나 이상의 REQUEST_TIME을 입력해야 검색이 가능합니다.
        </div>
      </template>
    </TagbyFilterV2>
    <TagbyList
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(msg_type)="data">
        {{ resolveMsgType(data.value) }}
      </template>

      <template #cell(result)="data">
        {{ data.value }} ({{ resolveResult(data.value) }})
      </template>

      <template #cell(request_time)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(save_time)="data">
        <div style="width: 90px;">
          {{ data.value }}
        </div>
      </template>
    </TagbyList>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import useInitialize from './useInitialize'
import useItems from './useItems'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
  },
  setup() {
    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      items,
      fetchItems,
      categoryOptions,
      filterParams,
      search,

      tableColumns,
      currentPage,
      perPage,
      totalRows,
      isBusy,
      changePage,

      hasRequestTime,
    } = useItems()
    items.value = []
    fetchItems()

    const resolveMsgType = value => {
      if (value === '3') {
        return 'MMS'
      }
      if (value === '6') {
        return 'KAKAO'
      }
      return ''
    }

    const resolveResult = value => {
      if (value === '100') {
        return '성공'
      }
      if (value === '201') {
        return '미등록 번호'
      }
      if (value === '302') {
        return '단말기 전원문제'
      }
      if (value === '306') {
        return '기타 에러'
      }
      if (value === '3008') {
        return '전화번호 오류'
      }
      if (value === '3015') {
        return '탬플릿 없음'
      }
      if (value === '3016') {
        return '내용와 탬플릿 불일치'
      }
      if (value === '3018') {
        return '메시지 전송 불가'
      }
      if (value === '7109') {
        return '수신여부 불투명'
      }
      return '알 수 없음'
    }

    return {
      items,
      fetchItems,
      categoryOptions,
      filterParams,
      search,

      tableColumns,
      currentPage,
      perPage,
      totalRows,
      isBusy,
      changePage,

      hasRequestTime,

      resolveMsgType,
      resolveResult,
    }
  },
})
</script>
