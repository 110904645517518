import {
  ref,
} from '@vue/composition-api'

export default {
  currentPage: ref(),
  perPage: ref(),
  totalRows: ref(),
  items: ref([]),
  isBusy: ref(false),
  filterParams: ref({}),
}
